"use client";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "@/components/Title";

const Brands = ({data}) => {
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container className={'brand'}>
                <Title text={data?.section_data?.subtitle} color={'#000000'} align={'center'} margin={'0 0 40px'}/>
                <Row>
                    {
                        data?.images?.list && data?.images?.list?.length > 0 &&
                        data?.images?.list?.map((e,i)=>{
                            return(
                                <Col md={3} className={'brand__single fade-up'} key={i}>
                                    <div className={'brand__single__img'}>
                                        <img src={e?.full_path} alt={'brand'}/>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #ffffff;
    .brand{
      &__single{
        height: auto;
        &__img{
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
            img{
                transform: scale(1.01);
                transition: transform 0.6s ease-in-out;
                width: 250px;
            }
            
            &:hover{
                img{
                    transform: scale(1.10);
                    transition: transform 0.6s ease-in-out;
                }
            }
        }
      }
    }
  
  @media(max-width: 767px){
    .brand__single{
     min-height: 90px;
      width: 50% !important;
    }
  }
`;

export default Brands;
