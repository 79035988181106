"use client";
import {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper";
import Title from "@/components/Title";
import BusinessBox from "@/components/business/BusinessBox";
import {ImageReveal} from "@/components/animations/ImageReveal";
import {TextAnimation} from "@/components/animations/TextAnimation";



const BusinessSlider = ({data, subtitle}) => {

    const [offset, setOffset] = useState(90)

    useEffect(() => {
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [])


    return (
        <StyledComponent className={'pt-120 pb-120'} offset={offset}>
            <Container>
                <Title text={subtitle} margin={'0 0 40px'} align={'center'}/>
                <div className={'navigation'}>
                    <ul>
                        <li id={'next-business'} className={'hover'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                <path id="Path_9659" data-name="Path 9659" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#137EC3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <path id="Path_9660" data-name="Path 9660" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                        </li>
                        <li id={'prev-business'} className={'hover'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                <path id="Path_9657" data-name="Path 9657" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                        </li>
                    </ul>
                </div>
                {
                    data &&
                    <Swiper
                        modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                        loop={true}
                        allowTouchMove={true}
                        autoplay={{
                            delay : 1500,
                            disableOnInteraction :false,
                        }}
                        slidesPerView={1}
                        spaceBetween={30}
                        pagination={false}
                        navigation={{
                            prevEl: '#prev-business',
                            nextEl: '#next-business',
                        }}
                        breakpoints={{
                            900: {
                                slidesPerView: 3,
                            },
                            550: {
                                slidesPerView: 1,
                            }

                        }}

                        speed={900}
                    >
                        {
                            data?.length > 0 &&
                            data?.map((e,i)=>{
                                return(
                                    <SwiperSlide key={i} className={'fade-up'}>
                                        <BusinessBox img={e?.data?.images?.list?.[0]?.full_path} title={e?.data?.product_data?.title} link={`/business-verticals/${e?.data?.product_data?.slug}`}/>
                                    </SwiperSlide>
                                )
                            })
                        }
                        {
                            data?.length < 8 ?
                                data?.map((e,i)=>{
                                    return(
                                        <SwiperSlide key={i} className={'fade-up'}>
                                            <BusinessBox img={e?.data?.images?.list?.[0]?.full_path} title={e?.data?.product_data?.title} link={`/business-verticals/${e?.data?.product_data?.slug}`}/>
                                        </SwiperSlide>
                                    )
                                }) : ''
                        }
                        {
                            data?.length > 0 &&
                            data?.map((e,i)=>{
                                return(
                                    <SwiperSlide key={i} className={'fade-up'}>
                                        <BusinessBox img={e?.data?.images?.list?.[0]?.full_path} title={e?.data?.product_data?.title} link={`/business-verticals/${e?.data?.product_data?.slug}`}/>
                                    </SwiperSlide>
                                );
                            })
                        }
                    </Swiper>
                }

                <div className={'navigation-mobile'}>
                    <ul>
                        <li id={'next-business'} className={'hover'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                <path id="Path_9659" data-name="Path 9659" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#137EC3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <path id="Path_9660" data-name="Path 9660" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                        </li>
                        <li id={'prev-business'} className={'hover'}>
                            <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                <path id="Path_9657" data-name="Path 9657" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                            </svg>
                        </li>
                    </ul>
                </div>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F9F9F9;
  .container{
    position: relative;
  }
  //overflow: hidden;

  .swiper{
    border-radius: 0 60px 0 60px !important;
    overflow: hidden;
  }
  .navigation{
    position: absolute;
    top: calc(50%);
    width: 100%;
    z-index: 999;
    left: 0;
    ul{
      display: flex;
      justify-content: space-between;
      gap: 15px;

      li{
        height: 40px;
        width: 40px;
        border: 1px solid #137EC3;
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        
        &:first-child{
          margin-left: -40px;
        }
        &:last-child{
          margin-right: -40px;
        }

        svg{
          position: relative;
          z-index: 999999;
          #Path_9658{
            display: none;
            transition: all 0.2s ease-in-out;
          }
          #Path_9660{
            display: none;
            transition: all 0.2s ease-in-out;
          }
        }

        &:hover{

          svg{
            #Path_9658{
              transition: all 0.2s ease-in-out;
              display: block !important;
            }
            #Path_9660{
              transition: all 0.2s ease-in-out;
              display: block !important;
            }
            #Path_9657{
              transition: all 0.2s ease-in-out;
              display: none;
            }
            #Path_9659{
              transition: all 0.2s ease-in-out;
              display: none;
            }
          }
        }
      }
    }
  }
  
  .navigation-mobile{
    display: none;
  }
  
  @media(max-width: 767px){
    .navigation{
      display: none;
    }
    
    .navigation-mobile{
      display: block;
      margin-top: 20px;
      ul{
        display: flex;
        justify-content: center;
        gap: 15px;

        li{
          height: 40px;
          width: 40px;
          border: 1px solid #137EC3;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          svg{
            position: relative;
            z-index: 999999;
            #Path_9658{
              display: none;
              transition: all 0.2s ease-in-out;
            }
            #Path_9660{
              display: none;
              transition: all 0.2s ease-in-out;
            }
          }

          &:hover{

            svg{
              #Path_9658{
                transition: all 0.2s ease-in-out;
                display: block !important;
              }
              #Path_9660{
                transition: all 0.2s ease-in-out;
                display: block !important;
              }
              #Path_9657{
                transition: all 0.2s ease-in-out;
                display: none;
              }
              #Path_9659{
                transition: all 0.2s ease-in-out;
                display: none;
              }
            }
          }
        }
      }
    }
  }
`;

export default BusinessSlider;
