import(/* webpackMode: "eager" */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\AllHome.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\Banner.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\Brands.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\BusinessSlider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\ChairmanMessage.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\Divisions.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\NewsSlider.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\Testimonial.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\home\\VideoSection.jsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "D:\\Projects\\Next Js\\2309SAG\\components\\WorkHard.jsx");
