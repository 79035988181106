'use client';
import {useEffect, useState} from "react";
import {getHomeApi} from "@/api/home";
import Banner from "@/components/home/Banner";
import BusinessSlider from "@/components/home/BusinessSlider";
import Brands from "@/components/home/Brands";
import ChairmanMessage from "@/components/home/ChairmanMessage";
import VideoSection from "@/components/home/VideoSection";
import Divisions from "@/components/home/Divisions";
import NewsSlider from "@/components/home/NewsSlider";
import Testimonial from "@/components/home/Testimonial";
import WorkHard from "@/components/WorkHard";
import {CSSPlugin, gsap} from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import { ScrollSmoother } from "gsap/dist/ScrollSmoother";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger, CSSPlugin);
    ScrollTrigger.refresh();
    const [getHomeData, setHomeData] = useState(null);

    useEffect(() => {
        const fetchAboutData = async () => {
            const data = await getHomeApi();
            setHomeData(data);
        };

        fetchAboutData();
    }, []);

    //Refactor
    const banner = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_banner');
    const brands = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_brands');
    const chairman = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_chairman');
    const video = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_video');
    const divisions = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_divisions');
    const work = getHomeData?.data?.sections?.find(f=>f?.section_data?.template === 'home_work_hard');
    const verticals = getHomeData?.feature_products;
    const news = getHomeData?.feature_news;
    const testimonial = getHomeData?.testimonial;


    useEffect(() => {
        if (typeof window !== 'undefined' && window.innerWidth > 767) {
            let smoother = ScrollSmoother.create({
                smooth: 2,
                effects: true,
            });
            ScrollTrigger.refresh();

            const hash = window.location.hash;
            if (hash) {
                setTimeout(() => {
                    smoother.scrollTo(hash, 3);
                }, 500);
            }
        }
    }, [getHomeData]);

    //image reveal
    useEffect(() => {

        let reveal = gsap.utils.toArray(".reveal-img");
        reveal.forEach((cont) => {
            let img = cont.querySelector("img");
            let tl = gsap.timeline({
                scrollTrigger: {
                    trigger: cont,
                    toggleActions: "restart none none reset",
                    once: true,
                },
            });

            // clip-path reveal
            tl.from(
                img,
                1.5,
                {
                    clipPath: "inset(0% 0% 100% 0%)", // Start from bottom
                    ease: "power2.inOut",
                },
                {
                    clipPath: "inset(0% 0% 0% 0%)", // Move to top
                    ease: "power2.inOut",
                }
            );
        });


    }, [ getHomeData]);
    //content animation
    useEffect(() => {
        let allAnim = document.querySelectorAll('.fade-up');
        allAnim.forEach((el, index) => {
            gsap.fromTo(el, {
                autoAlpha: 0,
                y: 100,
                ease: "none",
            }, {
                y: 0,
                autoAlpha: 1,
                ease: "power2",
                duration: 1.5,
                scrollTrigger: {
                    id: `${index + 1}`,
                    trigger: el,
                    start: 'top center+=150',
                    toggleActions: 'play none none reverse',
                }
            })
        })
        return (() => {
            allAnim = ''
        })
    }, [ getHomeData]);
    //text animation
    useEffect(() => {
        // ScrollTrigger.refresh();
        // document.fonts.ready.then(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {
            const parentSplit = new SplitText(item, {
                wordsClass: "split-parent",
                type: "words",
                reduceWhiteSpace: false
            })
            const childSplit = new SplitText(item, {
                type: "words",
                wordsClass: "split-child",
                reduceWhiteSpace: false
            })

            const tl = gsap.timeline()

            childSplit.words.forEach(i => {

                i.parentNode.style.height = i.clientHeight + 'px'
                i.parentNode.style.overflow = 'hidden'
                // i.parentNode.style.background = 'red'
            })

            gsap.from(childSplit.words, {
                duration: 1,
                // delay: .02,
                yPercent: 150,
                alpha: 1,
                // ease: 'power4.out',
                // stagger: .06,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: '-250',
                    // start: "top top",
                    end: `+ ${item.clientHeight}`,
                    // markers: true
                    once: true,
                }
            })
        });                          // do stuff

        // })

    }, [getHomeData])


    return (
        <>
            <Banner data={banner}/>
            <BusinessSlider data={verticals}/>
            <Brands data={brands}/>
            <ChairmanMessage data={chairman}/>
            <VideoSection data={video}/>
            <Divisions data={divisions}/>
            <NewsSlider data={news}/>
            <Testimonial data={testimonial}/>
            <WorkHard data={work}/>
        </>
    );
};


export default MyComponent;
