import { ScrollTrigger } from "gsap/ScrollTrigger";
import { CSSPlugin, gsap } from "gsap";
import { useEffect } from "react";
import { usePathname } from "next/navigation";
gsap.registerPlugin(ScrollTrigger, CSSPlugin);
export const ImageReveal = () => {
    const location = usePathname();

    useEffect(() => {
        let reveal = gsap.utils.toArray(".reveal-img");

        reveal.forEach((cont) => {
            let img = cont.querySelector("img");

            // Check if the image is already in the viewport
            const rect = cont.getBoundingClientRect();
            const isInViewport = rect.top < window.innerHeight && rect.bottom >= 0;

            if (!isInViewport) {
                let tl = gsap.timeline({
                    scrollTrigger: {
                        trigger: cont,
                        toggleActions: "play none none reset",
                        once: true,
                        start: "clamp(top bottom)",
                        end: "clamp(bottom bottom)",
                        markers: false,
                        fastScrollEnd: true,
                        refreshPriority: 1,
                    },
                });

                // clip-path reveal
                tl.fromTo(
                    img,
                    { clipPath: "inset(0% 0% 100% 0%)" }, // Start from bottom
                    {
                        clipPath: "inset(0% 0% 0% 0%)", // Move to top
                        duration: 1.5,
                        ease: "power2.inOut",
                    }
                );
            } else {
                // If the image is already in the viewport, set it to fully revealed
                gsap.set(img, { clipPath: "inset(0% 0% 0% 0%)" });
            }
        });
    }, [location]);

    useEffect(() => {
        let allAnim = document.querySelectorAll('.fade-up');

        allAnim.forEach((el, index) => {
            // Check if the element is already in the viewport
            const rect = el.getBoundingClientRect();
            const isInViewport = rect.top < window.innerHeight && rect.bottom >= 0;

            if (!isInViewport) {
                gsap.fromTo(el,
                    {
                        autoAlpha: 0,
                        y: 100,
                        ease: "none",
                    },
                    {
                        y: 0,
                        autoAlpha: 1,
                        ease: "power2",
                        duration: 1.5,
                        scrollTrigger: {
                            id: `${index + 1}`,
                            trigger: el,
                            start: "clamp(top bottom)",
                            toggleActions: 'play none none none',
                            once: true,
                        }
                    }
                );
            } else {
                // If the element is already in the viewport, set it to fully visible
                gsap.set(el, { autoAlpha: 1, y: 0 });
            }
        });

        // Cleanup function
        return () => {
            ScrollTrigger.getAll().forEach(trigger => trigger.kill());
        };
    }, [location]);
};