"use client";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import NewsBox from "../NewsBox";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper";
import Title from "@/components/Title";
import Button from "@/components/Button";
import reactHtmlParser from "react-html-parser";

const NewsSlider = ({data}) => {


    const bloglist = data?.filter(f=>f?.data?.category_id !== 18);

    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <div>
                    <div className={'top'}>
                        <Title text={'News & Events'}/>
                        <ul>
                            <li id={'prev'} className={`hover ${bloglist?.length <= 3 ? 'disabled' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                    <path id="Path_9659" data-name="Path 9659" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_9660" data-name="Path 9660" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </svg>
                            </li>
                            <li id={'next'} className={`hover ${bloglist?.length <= 3 ? 'disabled' : ''}`}>
                                <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                    <path id="Path_9657" data-name="Path 9657" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                </svg>
                            </li>
                        </ul>
                    </div>
                    {
                        bloglist &&
                        <Swiper
                            Swiper
                            modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                            loop={true}
                            allowTouchMove={true}
                            autoplay={false}
                            slidesPerView={1}
                            spaceBetween={20}
                            // autoplay={{
                            //     delay: 4000,
                            //     disableOnInteraction: false,
                            // }}
                            initialSlide={1}
                            pagination={false}
                            navigation={{
                                prevEl: '#prev',
                                nextEl: '#next',
                            }}
                            breakpoints={{
                                900: {
                                    slidesPerView: 3,
                                    allowTouchMove: false,
                                    spaceBetween: 30
                                },
                                550: {
                                    slidesPerView: 2,
                                }

                            }}

                            speed={900}
                        >
                            {
                                bloglist?.length > 0 &&
                                bloglist?.map((e,i)=>{
                                    return(
                                        <SwiperSlide key={i}>
                                            <NewsBox img={e?.images?.list?.[0]?.full_path}
                                                     title={reactHtmlParser(e?.data?.title)}
                                                     link={e?.data?.slug}/>
                                        </SwiperSlide>
                                    );
                                })
                            }
                        </Swiper>
                    }
                </div>
                <Button text={'View All News'} src={'news-events'} margin={'40px 0 0'}/>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .top{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 40px;
      
      .title{
        margin-bottom: 0;
      }
      
      ul{
        display: flex;
        gap: 15px;
        
        li{
          height: 40px;
          width: 40px;
          border: 1px solid #137EC3;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

            &.disabled {
                opacity: 0.5;
                cursor: not-allowed;
                pointer-events: none;
            }
          
          svg{
            position: relative;
            z-index: 999999;
            #Path_9658{
              display: none;
              transition: all 0.2s ease-in-out;
            }
            #Path_9660{
              display: none;
              transition: all 0.2s ease-in-out;
            }
          }
          
          &:hover{
            
            svg{
              #Path_9658{
                transition: all 0.2s ease-in-out;
                display: block !important;
              }
              #Path_9660{
                transition: all 0.2s ease-in-out;
                display: block !important;
              }
              #Path_9657{
                transition: all 0.2s ease-in-out;
                display: none;
              }
              #Path_9659{
                transition: all 0.2s ease-in-out;
                display: none;
              }
            }
          }
            @media(max-width: 767px){
                &.disabled {
                    opacity: 1;
                    cursor: unset;
                    pointer-events: unset;
                }
            }
        }
      }
    }
`;

export default NewsSlider;
