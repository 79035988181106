import {gsap} from "gsap";
import {SplitText} from "gsap/SplitText";
import {useEffect} from "react";
import {usePathname} from "next/navigation";


export const TextAnimation = () => {
    gsap.registerPlugin(SplitText);
    // gsap.registerPlugin(ScrollTrigger);


    useEffect(() => {
        // ScrollTrigger.refresh();
        // document.fonts.ready.then(() => {

        gsap.utils.toArray('.split-up').forEach((item, i) => {

            const parentSplit = new SplitText(item, {
                wordsClass: "split-parent",
                type: "words",
                reduceWhiteSpace: false
            })
            const childSplit = new SplitText(item, {
                type: "words",
                wordsClass: "split-child",
                reduceWhiteSpace: false
            })

            const tl = gsap.timeline()

            childSplit.words.forEach(i => {

                i.parentNode.style.height = i.clientHeight + 'px'
                i.parentNode.style.overflow = 'hidden'
                // i.parentNode.style.background = 'red'
            })

            gsap.from(childSplit.words, {
                duration: 1,
                // delay: .02,
                yPercent: 150,
                alpha: 1,
                // ease: 'power4.out',
                // stagger: .06,
                scrollTrigger: {
                    trigger: item,
                    toggleActions: "restart none none reset",
                    // start: '-250',
                    start: "clamp(top bottom)",
                    end: "clamp(bottom bottom)",
                    // markers: true,
                    once: true,
                },
            })
        });                          // do stuff

        // })

    }, [])
}


/*---how to use

- add 'split-up' class on the tag like(h1,h2,h3,p ... etc)

*/
