"use client";
import styled from "styled-components";
import { useState } from "react";

export const VideoBanner = ({
                                src,
                                position,
                                objectFit,
                                height,
                                width,
                                banner,
                                alt,
                                left,
                                margin,
                                right,
                                top,
                                bottom,
                                parallax,
                                className,
                            }) => {
    const [isMuted, setIsMuted] = useState(true);

    const handleMuteToggle = () => {
        setIsMuted(prev => !prev);
    };

    return (
        <StyledImg
            className={`global-image ${parallax ? "parallax-bg" : ""}`}
            objectFit={objectFit}
            margin={margin}
            position={position}
            left={left}
            right={right}
            top={top}
            bottom={bottom}
            height={height}
            width={width}
            // style={
            //     parallax && {
            //         backgroundImage: `url(${src})`,
            //         backgroundSize: "cover",
            //         backgroundPosition: "center",
            //     }
            // }
        >
            {/*{!parallax && (*/}

            {/*)}*/}
            <video
                className={className ? className : ""}
                src={src ? src : "videos/dynamic/video.mp4"}
                alt={alt || ""}
                autoPlay
                muted={isMuted}
                loop
                playsInline
            />
            <div onClick={handleMuteToggle} className="sound-icon mute-button">
                <svg
                    id="Component_16_1"
                    data-name="Component 16 – 1"
                    xmlns="http://www.w3.org/2000/svg"
                    width="17"
                    height="18.745"
                    viewBox="0 0 17 18.745"
                >
                    {/* Show different paths based on mute state */}
                    <path
                        id="Path_7655"
                        data-name="Path 7655"
                        d="M412.414,70.758a.586.586,0,0,0-.829.829,8.838,8.838,0,0,1,0,12.485.586.586,0,1,0,.829.829,10,10,0,0,0,0-14.142Z"
                        transform="translate(-398.343 -68.456)"
                        fill="#fff"
                        opacity={isMuted ? "0" : "1"}
                    />
                    <path
                        id="Path_7656"
                        data-name="Path 7656"
                        d="M366.968,116.2a.586.586,0,0,0-.829.829,6.317,6.317,0,0,1,0,8.935.586.586,0,0,0,.829.829,7.489,7.489,0,0,0,0-10.592Z"
                        transform="translate(-354.672 -112.125)"
                        fill="#fff"
                        opacity={isMuted ? "0" : "1"}
                    />
                    <path
                        id="Path_7657"
                        data-name="Path 7657"
                        d="M12.264,16.21a1.5,1.5,0,0,0-1.534.143l-6.547,4.1H2.155A2.157,2.157,0,0,0,0,22.607v5.648A2.157,2.157,0,0,0,2.155,30.41H4.183l6.547,4.1a1.776,1.776,0,0,0,.934.295,1.228,1.228,0,0,0,.6-.152,1.5,1.5,0,0,0,.693-1.376V17.586a1.5,1.5,0,0,0-.693-1.376Zm-8.5,13.028H2.155a.984.984,0,0,1-.983-.983V22.607a.984.984,0,0,1,.983-.983H3.766Zm8.02,4.038c0,.248-.071.341-.089.351s-.134.021-.344-.111L4.937,29.5V21.362l6.415-4.016c.211-.132.327-.121.344-.111s.089.1.089.351Z"
                        transform="translate(0 -16.059)"
                        fill="#fff"
                    />
                </svg>
            </div>
        </StyledImg>
    );
};

const StyledImg = styled.div`
    position: ${(props) => props.position || "absolute"};
    height: ${(props) => props.height || "100%"};
    width: ${(props) => props.width || "100%"};
    top: ${(props) => props.top || 0};
    left: ${(props) => props.left || 0};
    bottom: ${(props) => props.bottom || 0};
    right: ${(props) => props.right || 0};
    margin: ${(props) => props.margin || 0};
    overflow: hidden;
    background-repeat: repeat !important;
    will-change: transform;

    video {
        width: 100%;
        height: 100%;
        object-fit: ${(props) => props.objectFit || "cover"};
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }

    .sound-icon {
        position: absolute;
        bottom: 180px;
        right: 30px;
        height: 35px;
        width: 35px;
        z-index: 9999999;
        cursor: pointer;
    }
    
    @media(max-width: 767px){
        .sound-icon{
            top: 115px;
            right: 15px;
            bottom: unset;
        }
    }
`;

export default VideoBanner;