"use client";
import {useEffect, useState} from 'react';
import styled from 'styled-components';
import {Col, Container, Modal, Row} from "react-bootstrap";
import Img from "@/components/Img";
import 'react-modal-video/css/modal-video.min.css'
import ImageParallax from "@/components/ImageParallax";
import Title from "@/components/Title";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import reactHtmlParser from "react-html-parser";
gsap.registerPlugin(ScrollTrigger);

const VideoSection = ({offset, padding, data}) => {
    ScrollTrigger.refresh();
    const [show, setShow] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => {
        setShow(true);
    }

    return (

       <>
           <StyledVideo data-scroll-container offset={offset}
                        className={'modal_video_popup video_body pb-120 pt-120'}>
               {/*<ModalVideo channel='youtube' isOpen={open} videoId={data?.url} onClose={() => handelOpen(false, '')}/>*/}

               <Container fluid>
                   <Title text={data?.section_data?.subtitle} margin={'0 0 40px'} align={'center'}/>
                   <Row>
                       <Col md={{span: 12}}>
                           <div onClick={() => handleShow(true, data?.section_data?.short_desc)} className="image_video_wrap">
                               <ImageParallax src={data?.images?.list?.[0]?.full_path}/>
                               <div className="play hover">
                                   <svg xmlns="http://www.w3.org/2000/svg" width="100" height="100" viewBox="0 0 100 100">
                                       <path id="Polygon_1" data-name="Polygon 1" d="M10,0,20,15H0Z"
                                             transform="translate(58.5 40) rotate(90)" fill="#004185"/>
                                   </svg>
                               </div>
                           </div>
                       </Col>
                   </Row>
               </Container>
           </StyledVideo>

           <Modal className={'modal-video'} show={show} onHide={handleClose}>

               <svg onClick={handleClose} className={'close-modal'} xmlns="http://www.w3.org/2000/svg" width="13.426"
                    height="13.423"
                    viewBox="0 0 13.426 13.423">
                   <path id="Icon_ionic-ios-close" data-name="Icon ionic-ios-close"
                         d="M19.589,18l4.8-4.8A1.124,1.124,0,0,0,22.8,11.616l-4.8,4.8-4.8-4.8A1.124,1.124,0,1,0,11.616,13.2l4.8,4.8-4.8,4.8A1.124,1.124,0,0,0,13.2,24.384l4.8-4.8,4.8,4.8A1.124,1.124,0,1,0,24.384,22.8Z"
                         transform="translate(-11.285 -11.289)" fill="#fff"/>
               </svg>


               <Modal.Body>
                   <iframe width="560" height="315"
                           src={data?.section_data?.short_desc}
                           title="YouTube video player" frameBorder="0"
                           allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                           allowFullScreen/>
               </Modal.Body>

           </Modal>

       </>


)
};


const StyledVideo = styled.div`
  position: relative;
  background-color: #F9F9F9;
  //background: #F7F4F4;
  //@keyframes zoomIt {
  //  0% {
  //    opacity: 1;
  //    transform: scale(1);
  //  }
  //  50% {
  //    transform: scale(1.03);
  //  }
  //  80% {
  //    opacity: 0;
  //  }
  //
  //  100% {
  //    opacity: 0;
  //  }
  //
  //}

  .col-md-12{
    padding: 0;
  }
  

  //&:after {
  //  content: '';
  //  background: white;
  //  height: 50vh;
  //  bottom: 0;
  //  left: 0;
  //  right: 0;
  //  z-index: 0;
  //  width: 100%;
  //  position: absolute;
  //  @media (max-width: 767px) {
  //    display: none;
  //  }
  //}


  .image_video_wrap {
    position: relative;
    padding-top: calc(600 / 1366 * 100%);
    cursor: pointer;
    overflow: hidden;
    

    .play {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      z-index: 2;
      height: 100px;
      width: 100px;
      border-radius: 50%;
      background-color: white;
      @media (max-width: 767px) {
        height: 50px;
        width: 50px;
        svg {
          height: 50px;
          width: 50px;
        }
      }

      &:before {
        content: '';
        position: absolute;
        height: calc(100% + 30px);
        width: calc(100% + 30px);
        border: none;
        background: #3CB649;
        left: -15px;
        top: -15px;
        border-radius: 50%;
        opacity: 0;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
        z-index: -4;
      }

      &:after {
        content: "";
        z-index: 0;
        //background-color: rgba(30, 92, 149, 0.5);
        overflow: hidden;
        border-radius: 50%;
        transition: 0.6s all cubic-bezier(0.4, 0, 0, 1);
      }

      svg {
        #Ellipse_396, path {
          transition: 0.7s all ease;
        }
      }
      &:hover{
        svg {
          position: absolute;
          z-index: 999;
          #Ellipse_396 {
            r: 50;
          }

          path {
            fill: white;
          }
        }
      }
    }
    @media (max-width: 767px) {
      padding-top: calc(250 / 375 * 100%);
    }
  }
`;


export default VideoSection;














