"use client";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "@/components/Img";
import Title from "@/components/Title";
import reactHtmlParser from "react-html-parser";


const ChairmanMessage = ({data}) => {
    const name = data?.section_data?.short_desc?.split(", ");

    return (
        <StyledComponent className={'chairman pb-120'}>
            <Container>
                <Row>
                    <Col md={7} className={'chairman__left fade-up'}>
                        <Title text={data?.section_data?.subtitle} margin={'0 0 40px'}/>
                        <p className={'split-up'}>
                            {reactHtmlParser(data?.section_data?.description)}
                        </p>
                        <h5 className={'split-up'}>{reactHtmlParser(name?.[0])}</h5>
                        <p className={'split-up'}>{reactHtmlParser(name?.[1])}</p>
                    </Col>
                    <Col md={5} className={'chairman__right'}>
                        <div className={'chairman__right__img reveal-img'}>
                            <Img src={data?.images?.list?.find(f=>f?.thumb === "on")?.full_path}/>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #ffffff;
  .row{
    align-items: center !important;
    
    @media(max-width: 767px){
      flex-direction: column-reverse;
    }
  }
    .chairman{
      &__left{
        padding-right: 70px;
        h5{
          margin-top: 40px;
          margin-bottom: 5px;
        }
      }
      &__right{
        &__img{
          position: relative;
          padding-top: calc(500 / 470 * 100%);
          .global-image{
            img{
              border-radius: 0 50px;
            }
          }
        }
      }
      
      @media(max-width: 767px){
        &__left{
          margin-top: 30px;
          padding-right: 15px;
          
          .title{
            text-align: center;
          }
        }
      }
    }
`;

export default ChairmanMessage;
