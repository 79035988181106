export async function getHomeApi(param) {
    const response = await fetch(
        `https://bestinbd.com/projects/web/2309SAG/api/get-req-data/sections?type=slug&value=${param}&get_section=yes&image=yes&post=yes&file=yes&gallery=yes`,
        {
            cache: "no-store"
        }
    );
    if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
    }
    const data = await response.json();
    return data;
}