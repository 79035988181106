"use client";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "@/components/Img";
import reactHtmlParser from "react-html-parser";

const Divisions = ({data}) => {
    return (
        <StyledComponent>
            <Container fluid>
                <Row className={'divisions'}>
                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e,i)=>{
                            return(
                                <Col md={4} className={'divisions__single fade-up'} key={i}>
                                    <div className={'divisions__single__img'}>
                                        <img src={e?.images[0]?.full_path} alt={''}/>
                                        <h2 className={'split-up'}>{reactHtmlParser(e?.data?.subtitle)}</h2>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .divisions{
      
      &__single{
        padding: 0;
        &__img{
          position: relative;
          padding-top: calc(400 / 455 * 100%);
          overflow: hidden;
          cursor: pointer;
            &:after{
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
                width: 100%;
                background-color: #000000;
                opacity: 0.3;
                z-index: 2;
            }
          img{
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            z-index: 1;
            transform: scale(1.01);
            transition: transform .7s ease-in-out;
              
              &:after{
                  content: '';
                  position: absolute;
                  top: 0;
                  left: 0;
                  height: 100%;
                  width: 100%;
                  background-color: #000000;
              }
          }
          
          h2{
            position: absolute;
            z-index: 4;
            width: 100%;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            text-align: center;
            color: #ffffff;
          }
          &:hover{
            img{
              transform: scale(1.10);
              transition: transform .7s ease-in-out;
            }
          }
        }
      }
    }
`;

export default Divisions;
