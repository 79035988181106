"use client";
import  {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '@/styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination, EffectCreative} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {CSSPlugin, gsap} from 'gsap';
import {SplitText} from "gsap/SplitText";
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Img from "@/components/Img";
import VideoBanner from "../VideoBanner";
import ImageParallax from "../ImageParallax";
import VisibilitySensor from "react-visibility-sensor";
import CountUp from "react-countup";
import {usePathname} from "next/navigation";
import reactHtmlParser from "react-html-parser";

const Banner = ({data}) => {
    const interleaveOffset = 0.5;
    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);
    gsap.registerPlugin(ScrollTrigger);
    const location = usePathname();
    const [offset, setOffset] = useState(90)


    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    const [deviceWidth, setDeviceWidth] = useState(0);

    // set device width
    useEffect(() => {
        function HandleWidth() {
            setDeviceWidth(window.innerWidth)
        }

        window.addEventListener('resize', HandleWidth)
        HandleWidth()
        return () => {
            window.removeEventListener('resize', HandleWidth)
        };
    });


    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };


    // handle slider progress
    // const handleProgress = (swiper,event) => {
    //
    //     var interleaveOffset = 1;
    //     for (let i = 0; i < swiper.slides.length; i++) {
    //         var slideProgress = swiper.slides[i].progress;
    //         var innerOffset = swiper.width * interleaveOffset;
    //         var innerTranslate = slideProgress * innerOffset;
    //         swiper.slides[i].querySelector(".global-image").style.transform =
    //             "translate3d(" + innerTranslate + "px, 0, 0)";
    //     }
    //
    // };

    // hand touch move not required this slider
    // const handleTouchStart = (swiper) => {
    //     for (let i = 0; i < swiper.slides.length; i++) {
    //         swiper.slides[i].style.transition = "";
    //     }
    // };


    // handle image transition on change
    // const handleSetTransition = (swiper, speed) => {
    //
    //     for (let i = 0; i < swiper.slides.length; i++) {
    //         swiper.slides[i].style.transition = `${speed}ms`;
    //         swiper.slides[i]
    //             .querySelector(".global-image")
    //             .style.transition = `${speed}ms`;
    //     }
    // };

    const handleProgress = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            const slide = swiper.slides[i];
            const progress = slide.progress;
            const translateX = progress * swiper.width;
            const image = slide.querySelector(".global-image");
            if (image) {
                image.style.transform = `translateX(${translateX}px)`;
            }
        }
    };

    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };

    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            const image = swiper.slides[i].querySelector(".global-image");
            if (image) {
                image.style.transition = `${speed}ms`;
            }
        }
    };



    // handle pagination
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    const handleSlideChange = (event) => {

        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }



    };


    return (
        <StyledBanner offset={offset} className={'home-banner parallax-data'} >
            {
                data?.images?.list &&
                <Swiper
                    ref={swiperRef}
                    spaceBetween={0}
                    loop={true}
                    speed={1000}
                    onSlideChange={(swiper) => {
                        handleSlideChange(swiper);
                    }}
                    onProgress={handleProgress}
                    onTouchStart={handleTouchStart}
                    onSetTransition={handleSetTransition}
                    grabCursor={false}
                    effect="creative"
                    creativeEffect={{
                        prev: {
                            translate: ['-100%', 0, 0],
                        },
                        next: {
                            translate: ['100%', 0, 0],
                        },
                    }}
                    pagination={{
                        clickable: true
                    }}
                    navigation={{
                        prevEl: '#banner-prev',
                        nextEl: '#banner-next',
                    }}
                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                    className="mySwiper main-swiper"
                >
                    {
                        data?.images?.list?.length > 0 &&
                        data?.images?.list?.map((e, i)=>{
                            return(
                                <SwiperSlide key={i}>
                                    <div className="slide-inner">
                                        {
                                            deviceWidth > 767 ?

                                                <div className="desktop">
                                                    {
                                                        e?.video === 'on'
                                                            ?
                                                            <VideoBanner src={e?.full_path}/>
                                                            :
                                                            <Img src={e?.full_path}/>
                                                    }
                                                </div>


                                                :

                                                <div className="mobile">
                                                    {
                                                        e?.video === 'on'
                                                            ?
                                                            <VideoBanner src={e?.full_path}/>
                                                            :
                                                            <Img src={e?.full_path}/>
                                                    }
                                                </div>

                                        }
                                    </div>
                                </SwiperSlide>
                            );
                        })
                    }

                </Swiper>
            }


            <Container className="navigation">
                <div>
                    <Row>
                        <Col>
                            <ul>
                                <li id={'banner-prev'} className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                        <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </svg>
                                </li>

                                <li id={'banner-next'} className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                        <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </svg>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
            <Container fluid className={'cards'}>
                <Row>
                    {
                        data?.posts?.list && data?.posts?.list?.length > 0 &&
                        data?.posts?.list?.map((e,i)=>{
                            const startValue = Math.round(e?.data?.short_desc * 0.9);
                            return(
                                <Col md={4} key={i}>
                                    <div className={'cards__single'}>
                                        <div className={'cards__single__icon'}>
                                            <img src={e?.images?.[0]?.full_path}/>
                                        </div>
                                        <div className={'cards__single__counter'}>
                                            <CountUp start={startValue} end={e?.data?.short_desc} duration={5}

                                                     separator=","
                                                     decimals={''}
                                                     decimal=","
                                                     suffix={'+'}
                                            >

                                                {({countUpRef, start}) => (
                                                    <VisibilitySensor onChange={start}
                                                                      partialVisibility={{top: 0, bottom: 20}}
                                                                      delayedCall>

                                                        <h4 ref={countUpRef}></h4>


                                                    </VisibilitySensor>

                                                )}

                                            </CountUp>
                                            <p>{reactHtmlParser(e?.data?.subtitle)}</p>
                                        </div>
                                    </div>
                                </Col>
                            );
                        })
                    }
                </Row>
            </Container>
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  position: relative;

  @media(max-width: 767px){
    height: 130svh !important;
    
    .swiper{
      height: 100svh;
    }
  }

  margin-top: -1px;
  
  
  .swiper-button-prev, .swiper-button-next , .swiper-pagination  {
    display: none;
  }
  
  .swiper-slide {
    overflow: hidden;
    .slide-inner {
      &__info {
        h2 {
          letter-spacing: -2.08px;
          //opacity: 0;
          //transition: opacity 0.5s ease;
        }
        p{
          //opacity: 0;
          //transition: opacity 0.5s ease;
        }

      }
    }
  }

  .swiper-button-disabled{
    opacity: 0.5 !important;
  }
  
  .main-swiper{
    .swiper-slide {
      transform: unset;
      -webkit-flex-shrink: 0;
      -ms-flex-negative: 0;
      flex-shrink: 0;
      backface-visibility: hidden;
    }

      .swiper-slide, .slide-inner {
          backface-visibility: hidden;
          transform-style: preserve-3d;
      }
  }

  //.swiper{
  //  height: 100vh;
  //}
    border-top:1px solid #C9C9C9!important;
  }
  //.swiper-wrapper{
  //   height: 100vh
  // }
  .slide-inner__info p{
  color: white;
  }
  .slider-inner__unset-padding{
  padding: unset;
    @media (max-width: 767px){
    padding: 0 15px;
  }
}

  .global-image{
      transition: clip-path 0.8s cubic-bezier(.29,.73,.45,1), border-color 3.6s linear, -webkit-clip-path 0.8s cubic-bezier(.29,.73,.45,1);
      overflow: hidden;
      -webkit-clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
      clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
      will-change: clip-path, transform;
      transform: translateZ(0);
  }



  
  .swiper-slide-active , .swiper-slide-visible{
    .global-image{
      clip-path: polygon(0 0,100% 0,100% 100%,0 100%);
        will-change: clip-path;
    }
  }


  



.slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      top: unset;
      bottom: 80px;
      transform: translateY(-50%);
      z-index: 2;
     }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      display: flex;
      justify-content: space-between;
      position: relative;
      overflow: hidden;
   
      @media (max-width: 767px){
        flex-direction: column;
        &__unset-padding{
          padding: unset;
          :first-child{
          padding-bottom: 40px;
        }
       }
        h2{
        margin: unset;
        }
      }

      h2 {
        font-weight: 500;
        color: #FFFFFF;
        font-family: ${title};
        position: relative;
        transform: translateY(400px);
        //transition: 2s all cubic-bezier(0.4, 0, 0, 1);
        margin: 0;
        //transition-delay: 1.2s;
      }
      p {
        //transition: 2s all cubic-bezier(0.4, 0, 0, 1);
        //transition-delay: 1.2s;
        padding-right: 30px;
        color: white!important;
        font-size: 20px;
        font-weight: 300;
        line-height: 24px;
      }
      
    }
  }

  @media (min-width: 768px){
    .slider-mobile{
      display: block;
  }
  }

.navigation {
  position: absolute;
  right: ${props => props.offset ? props.offset + 'px' : '140px'};
  left: ${props => props.offset ? props.offset + 'px' : '140px'};
  top: calc(50%);
  transform: translateY(-50%);
  display: inline-block;
  z-index: 1;

  ul {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: transparent;
      border: 1px solid #F9F5F2;

      

      &.hover:after {
        background-color: #137EC3;
      }

      position: relative;
      border-radius: 50%;
      height: 40px;
      width: 40px;

      svg {
        position: relative;

        #Ellipse_4378 {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
          r: 0;
        }

        &:hover {
          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 26px;
          }
        }
      }
      &:hover{
        border: 1px solid #137EC3;
        svg{
            position: relative;
            z-index: 2;
          g{
            line{
              stroke:  #FCFEFF;
            }
          }
        }
      }
    }
  }
  @media(max-width: 767px){
    top: unset;
    right: 0;
    left: 0;
    bottom: calc(45%);
    ul{
      flex-direction: unset;
      justify-content: flex-end;
      li{
        &:last-child{
          margin-top: 0;
        }
      }
    }
  }
}
  

.cards{
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: inline-block;
  z-index: 1;
  //background-color: transparent;
  //backdrop-filter: blur(10px);
    background: rgba(255, 255, 255, 0.08); /* Use a semi-transparent color */
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  
  .col-md-4{
    padding: 0;

    &:last-child{
      .cards__single{
        border-right: 0 !important;
      }
    }
  }
  
  &__single{
    padding: 40px;
    display: flex;
    gap: 15px;
    border-right: 1px solid rgba(255, 255, 255, 0.5);
    
    &__counter{
      color: #ffffff;
      h4{
        color: #ffffff;
        margin-bottom: 10px;
      }
      p{
        font-size: 16px;
        line-height: 20px;
        color: #ffffff;
      }
    }
  }
  
  @media(max-width: 768px){
    //position: relative;
    bottom: 0px !important;
    background-color: #2C7EC3;
    overflow: hidden;
    border-top: 0;
    .col-md-4{
      &:last-child{
        border-bottom: 0;
      }
    }
    
    .cards__single{
      padding: 25px 15px;
      border-right: 0;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
    }
  }
}

  //responsive
  @media (min-width: 1550px) {
    .slide-inner {
      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 80px;
          line-height: 100px;
        }
        
      }
    }
    .swipper-navigation-slider-custom{
      bottom: 300px;
    }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 40px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .swipper-navigation-slider-custom {
      bottom: 40px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }
  }

    @media (min-width: 768px) {
      .mobile {
        display: none;
      }
    }
    
  @media (max-width: 767px) {
    
    height: 100vh;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 40%;
      }

      &__info {
        position: relative;
        overflow: hidden;

        h2 {
          font-size: 40px;
          line-height: 48px;
        }
        
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }
    
    .navigation{
      left: 0;
        ul{
            li{
                &:hover{
                    
                }
            }
        }
    }
  }


      @supports (-moz-appearance: none) {
          .global-image {
              transform: translateZ(0); /* Force hardware acceleration */
          }
      }
    
    

`;


export default Banner;
