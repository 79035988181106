"use client";
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Img from "@/components/Img";
import Title from "@/components/Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Autoplay, EffectCreative, Navigation, Pagination} from "swiper";
import reactHtmlParser from "react-html-parser";

const Testimonial = ({data}) => {

    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container className={'testimonial'}>
                <Row className={'testimonial__single'}>
                    <Col md={5} className={'testimonial__single__left'}>
                        {
                            data &&
                            <Swiper Swiper
                                    modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                    loop={true}
                                    allowTouchMove={false}
                                    autoplay={false}
                                    slidesPerView={1}
                                    spaceBetween={0}
                                    effect={"mask"}
                                // autoplay={{
                                //     delay: 4000,
                                //     disableOnInteraction: false,
                                // }}
                                    initialSlide={1}
                                    pagination={false}
                                    navigation={{
                                        prevEl: '#prev',
                                        nextEl: '#next',
                                    }}
                                    speed={900}>
                                {
                                    data?.length > 0 &&
                                    data?.map((e,i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className={'image-wrapper reveal-img'}>
                                                    <Img src={e?.images?.list?.[0]?.full_path} alt={'testimonial'}/>
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        }

                    </Col>
                    <Col md={{offset:1, span:6}} className={'testimonial__single__right'}>
                        <Title text={'Stories of Success'} margin={'0 0 40px'}/>
                        {
                            data &&
                            <Swiper
                                Swiper
                                modules={[Autoplay, Pagination, Navigation, EffectCreative]}
                                loop={true}
                                allowTouchMove={false}
                                autoplay={false}
                                slidesPerView={1}
                                spaceBetween={0}
                                effect={"mask"}
                                // autoplay={{
                                //     delay: 4000,
                                //     disableOnInteraction: false,
                                // }}
                                initialSlide={1}
                                pagination={false}
                                navigation={{
                                    prevEl: '#prev',
                                    nextEl: '#next',
                                }}
                                speed={900}
                            >
                                {
                                    data?.length > 0 &&
                                    data?.map((e,i)=>{
                                        return(
                                            <SwiperSlide key={i}>
                                                <div className="text-wrapper">

                                                    <p>
                                                        {reactHtmlParser(e?.data?.body)}
                                                    </p>

                                                    <h5>{e?.data?.name}</h5>
                                                    {/*<p className={'name'}>{e?.data?.name}</p>*/}
                                                </div>
                                            </SwiperSlide>
                                        );
                                    })
                                }
                            </Swiper>
                        }

                        <div className={'navigation'}>
                            <ul>
                                <li id={'next'} className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                        <path id="Path_9659" data-name="Path 9659" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_9660" data-name="Path 9660" d="M118.869,2934.275l-6,6,6,6" transform="translate(-111.87 -2932.861)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </svg>
                                </li>
                                <li id={'prev'} className={'hover'}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="8.414" height="14.828" viewBox="0 0 8.414 14.828">
                                        <path id="Path_9657" data-name="Path 9657" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#137ec3" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                        <path id="Path_9658" data-name="Path 9658" d="M118.869,2934.275l-6,6,6,6" transform="translate(120.283 2947.689) rotate(180)" fill="none" stroke="#ffffff" stroke-linecap="round" stroke-linejoin="round" stroke-width="2"/>
                                    </svg>
                                </li>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F9F9F9;
    .testimonial{
      &__single{
        align-items: center;
        
        &__left{
          .swiper{
            border-radius: 0 60px 0 60px !important;
            overflow: hidden;
          }
          .image-wrapper{
            position: relative;
            padding-top: calc(600 / 470 * 100%);
            overflow: hidden;
          }
        }
        &__right{
         .text-wrapper{
           h5{
             margin-top: 40px;
             margin-bottom: 5px;
           }
           p{
             font-size: 16px;
             line-height: 24px;
           }
           //.name{
           //  font-size: 14px;
           //  line-height: 24px;
           //  font-weight: 400;
           //}
         }
          
          .navigation{
            margin-top: 40px;
            ul{
              display: flex;
              gap: 15px;

              li{
                height: 40px;
                width: 40px;
                border: 1px solid #137EC3;
                border-radius: 50%;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                svg{
                  position: relative;
                  z-index: 999999;
                  #Path_9658{
                    display: none;
                    transition: all 0.2s ease-in-out;
                  }
                  #Path_9660{
                    display: none;
                    transition: all 0.2s ease-in-out;
                  }
                }

                &:hover{

                  svg{
                    #Path_9658{
                      transition: all 0.2s ease-in-out;
                      display: block !important;
                    }
                    #Path_9660{
                      transition: all 0.2s ease-in-out;
                      display: block !important;
                    }
                    #Path_9657{
                      transition: all 0.2s ease-in-out;
                      display: none;
                    }
                    #Path_9659{
                      transition: all 0.2s ease-in-out;
                      display: none;
                    }
                  }
                }
              }
            }
          }
        }
      }
      
      @media(max-width: 767px){
        
        .swiper-slide{
          height: max-content !important;
        }
        &__single{
          &__left{
            margin-bottom: 30px;
          }
          &__right{
            .text-wrapper{
              .title{
                margin-bottom: 20px !important;
              }
              
              h5{
                margin-top: 20px;
                margin-bottom: 5px;
              }
            }
          }
        }
      }
    }
`;

export default Testimonial;
