"use client";
import styled from "styled-components";
import Img from "@/components/Img";
import Link from 'next/link'



const  BusinessBox = ({title, img, link}) => {
    return (
        <StyledComponent className={'fade-up'}>

            <div className={'wrapper'}>
               <Link href={link}>
                   <Img src={img ? img : ''}/>
                   <div className={'text'}>
                       <h5>{title ? title : ''}</h5>
                       <p>
                           Explore
                       </p>
                   </div>
               </Link>
            </div>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .wrapper{
    border-radius: 0 60px;
    overflow: hidden;
    position: relative;
    padding-top: calc(480 / 370* 100%);
    .global-image{
      img{
        transform: scale(1);
        transition:transform .7s cubic-bezier(0.4, 0, 0, 1);
      }
    }
    .text{
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      background-color: rgba(19 126 195 / 0.5);
        padding: 25px;
      
      h5{
        color: #ffffff;
          text-align: center;
      }
      P{
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 1.4px;
          color: #ffffff;
      }
    }
    
    &:hover{
      cursor: pointer;
      .global-image{
        img{
          transform: scale(1.08);
          transition: transform .7s cubic-bezier(0.4, 0, 0, 1);
        }
      }
    }
  }
`;


export default BusinessBox;